/* eslint-disable */
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import ReactDOMServer from 'react-dom/server';
import hypernova, { load, serialize } from 'hypernova';
import { createGenerateClassName, ServerStyleSheets } from '@material-ui/core/styles';
import { configService as configurationService } from '@next-common/config-service';
import { RenderReactFunc } from '../types';
import { projectAssetPrefix } from '../../conf/webpack.1.paths';
import { hypernovaWrapperClassName } from '../utils/hypernovaWrapperClassName';
import createEmotionServer from '@emotion/server/create-instance';

import { CacheProvider } from '@emotion/react';

import { cacheWithScope } from '@next-common/mui';

// const debug = initDebug('canopy:renderReactCustom');
const customErrorComponents = ['ProductImage'];
export const renderReact: RenderReactFunc = (name: string, Component: any) =>
  hypernova({
    server() {
      const configApi = configurationService.get();

      return (props: any, context: any) => {
        // console.log('renderReact props', props)
        if (customErrorComponents.includes(name) && props?.customStatusCode && props?.customStatusCode !== 200) {
          return null;
        }
        const sheets = new ServerStyleSheets({
          serverGenerateClassName: createGenerateClassName({
            productionPrefix: name.substr(0, 3),
            disableGlobal: true,
          }),
        });

        const cache = cacheWithScope(hypernovaWrapperClassName);
        // const cache = createCache({ key: 'css' });
        const { extractCriticalToChunks, constructStyleTagsFromChunks } = createEmotionServer(cache);

        const ComponentWithCache = (_props) => (
          <CacheProvider value={cache}>
            <Component {..._props} />
          </CacheProvider>
        );
        let html = ReactDOMServer.renderToString(sheets.collect(React.createElement(ComponentWithCache, props)));
        const css = sheets.toString();

        const emotionChunks = extractCriticalToChunks(html);
        const emotionCss = constructStyleTagsFromChunks(emotionChunks);

        const webpackRuntimePublicPath = `<script type="application/javascript">window.runtimePublicPath='${configApi.cdn}${projectAssetPrefix}/javascripts/';</script>`;
        html = `<style id="jss-server-side-${props.moduleName}">${css}</style>
        ${emotionCss}
        ${webpackRuntimePublicPath}${html}`;

        // const html = ReactDOMServer.renderToString(React.createElement(Component, props));
        const serialized = serialize(name, html, props);
        // console.log('serialized', serialized)
        return serialized;
      };
    },

    client() {
      const payloads: any[] = load(name);

      // debug('payloads', payloads);

      if (payloads) {
        payloads.forEach((payload) => {
          const { node, data } = payload;

          // const preloadedState = data.initialReduxState;
          // // initialReduxState  initialApolloState
          //
          //
          // let apolloPreloadedState;
          // let apolloPreloadedVariables;
          //
          // if (data && data.initialApolloState && data.initialApolloState.cache) {
          //   apolloPreloadedState = data.initialApolloState.cache;
          //   apolloPreloadedVariables = data.initialApolloState.variables;
          // }
          // // TODO: enable this when you want HTML caching
          // //  the SSR created md could not be correct, because it chould cahed in the cdn
          // if (preloadedState && !preloadedState.md) {
          //   console.log('client side md.detect');
          //   // eslint-disable-next-line no-param-reassign
          //   preloadedState.md = mdUtil.detect(window.navigator.userAgent);
          // }
          // const apolloClient = initializeApollo(apolloPreloadedState);
          // // Create the store
          // const store = createConnectedStore(history, Component.reducers,
          //   preloadedState, apolloClient);
          //
          // // @ts-ignore
          // window.API_MANIFEST = {};
          // const props = {
          //   ...data,
          //   ...preloadedState,
          //   store,
          //   history,
          //   apolloClient }
          // debug('props', props);
          //
          // @ts-ignore
          window.API_MANIFEST = window.API_MANIFEST || {};
          // @ts-ignore
          window.API_MANIFEST.cdn = '/vstatic/0/';

          // const cache = createCache({ key: 'css' });
          const cache = cacheWithScope(hypernovaWrapperClassName);

          const ComponentWithCache = (_props) => (
            <CacheProvider value={cache}>
              <Component {..._props} />
            </CacheProvider>
          );

          const element = React.createElement(ComponentWithCache, data);

          hydrateRoot(node, element);
        });
      }

      return Component;
    },
  });
