const path = require('path');

const projectAssetPrefix = 'hypernova-common'

module.exports = {
  projectAssetPrefix,
  rootDir: path.resolve(__dirname, '..'),
  configDir: path.resolve(__dirname, '..', 'conf'),
  srcDir: path.resolve(__dirname, '..', 'src'),
  destDir: path.resolve(__dirname, '..', 'dist/assets', projectAssetPrefix),
  commonjsDir: path.resolve(__dirname, '..', 'dist/commonjs'),
  serverDestDir: path.resolve(__dirname, '..', 'dist'),
  images: path.resolve(__dirname, '..', 'assets', projectAssetPrefix, 'images'),
  stylesheets: path.resolve(__dirname, '..', 'assets', projectAssetPrefix, 'stylesheets'),
  fonts: path.resolve(__dirname, '..', 'node_modules', '@next-common/global-css', 'src', 'fonts'),
  publicPath: '/vstatic/0/'+projectAssetPrefix+'/javascripts/',
};
